import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button, Alert } from "reactstrap";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthUser from "./AuthUser";
import { BASE_URL } from "../../server";
import sha256 from "crypto-js/sha256";
import arrowLeft from "../../assets/icons/arrowLeft.svg";

const LoginForm = () => {
  const location = useLocation();
  const registrazione = location?.state?.registrazione;
  const isMobile = sessionStorage.getItem("mobile") || false;

  const { http, setToken } = AuthUser();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [pin, setPin] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isRegistrazione, setIsRegistrazione] = useState(registrazione);

  const handleFirstnameChange = (e) => {
    setFirstname(e.target.value);
  };

  const handleLastnameChange = (e) => {
    setLastname(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePinChange = (e) => {
    setPin(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateForm = () => {
    if (!firstname || !lastname) {
      setError("Inserisci nome e cognome.");
      return false;
    }
    return true;
  };

  const navigate = useNavigate();
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      // trim and lowercase firstname and lastname
      const trimmedFirstname = firstname?.trim().toLowerCase();
      const trimmedLastname = lastname?.trim().toLowerCase();
      const trimmedPhoneNumber = phoneNumber?.trim();
      const trimmedPin = pin?.trim();
      const dataToHash = `${trimmedFirstname}${trimmedLastname}${trimmedPhoneNumber}${trimmedPin}`;
      const hashedData = sha256(dataToHash).toString();
      const token = btoa(`${hashedData}:${hashedData}`);
      const login = await axios.post(
        `${BASE_URL}/user/login`,
        {
          registration: isRegistrazione,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // basic auth // base64 encoded string of username:password
            Authorization: `Basic ${token}`,
          },
        }
      );

      if (login?.data?.profile) {
        /*  setToken(response.profile, response.token); */
        sessionStorage.setItem("user", JSON.stringify(login.profile));
        sessionStorage.setItem("basic_token", token);
        sessionStorage.setItem("client_id", login.data.profile.id);
        // UPDATE USER PROFILE
        try {
          const apiUrl = `${BASE_URL}/update_user`;
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          };
          const data = {
            profile: {
              id: login.data.profile.id,
              firstname,
              lastname,
              email,
              phone: phoneNumber,
              competence_id: 4,
            },
          };
          const response = await axios.put(apiUrl, data, { headers: headers });
          /*    console.log("update response", response); */
          if (response.status === 200) {
            sessionStorage.setItem(
              "user",
              JSON.stringify(
                Object.assign({}, login.data.profile, {
                  firstname,
                  lastname,
                  email,
                  phone: phoneNumber,
                })
              )
            );
            navigate("/user");
          } else {
            setError("Login fallito. Verifica le tue credenziali.");
          }
        } catch (error) {
          console.error(error);
          setError("Si è verificato un errore durante il login.");
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      setError("Si è verificato un errore durante il login.");
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <div className="row">
        <div
          className={!!isMobile ? "padding-mobile" : "shadow p-5 mt-5 mb-5 "}
        >
          <div className="mb-3" onClick={() => navigate(-1)}>
            <a href="#" className="flex items-center">
              <img
                src={`${arrowLeft}`}
                className={"icon"}
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "4px",
                }}
                alt={"Torna alla pagina precedente"}
              />
              Indietro
            </a>
          </div>
          <div className="mb-5">
            {!!isRegistrazione ? <h2>Registrazione</h2> : <h2>Accedi</h2>}
            {!!isRegistrazione ? (
              <p>Inserisci i tuoi dati per registrazione al servizio.</p>
            ) : (
              <p> Inserisci i tuoi dati per accedere al servizio.</p>
            )}
          </div>

          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={submitForm}>
            {/*<FormGroup>*/}
            {/*  <Label for="username" className="font-semibold">*/}
            {/*    Nome utente*/}
            {/*  </Label>*/}
            {/*  <Input*/}
            {/*    type="text"*/}
            {/*    name="username"*/}
            {/*    id="username"*/}
            {/*    placeholder="Inserisci il tuo username"*/}
            {/*    value={username}*/}
            {/*    onChange={handleUsernameChange}*/}
            {/*  />*/}
            {/*</FormGroup>*/}

            <FormGroup>
              <Label for="firstname" className="font-semibold">
                Nome
              </Label>
              <Input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Inserisci il tuo nome"
                value={firstname}
                onChange={handleFirstnameChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastname" className="font-semibold">
                Cognome
              </Label>
              <Input
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Inserisci il tuo cognome"
                value={lastname}
                onChange={handleLastnameChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phoneNumber" className="font-semibold">
                Numero di telefono
              </Label>
              <Input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="Inserisci il tuo numero di telefono"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="pin" className="font-semibold">
                PIN
              </Label>
              <Input
                type="password"
                name="pin"
                id="pin"
                placeholder="Inserisci il tuo PIN"
                value={pin}
                onChange={handlePinChange}
              />
            </FormGroup>
            {!!isRegistrazione ? (
              <FormGroup>
                <Label for="email" className="font-semibold">
                  Indirizzo email (opzionale)
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Inserisci il tuo indirizzo email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </FormGroup>
            ) : (
              <div></div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {!!isRegistrazione ? (
                <p>
                  Sei già registrato?{" "}
                  <span
                    style={{
                      color: "#007bff",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => setIsRegistrazione(false)}
                  >
                    Accedi
                  </span>
                </p>
              ) : (
                <p>
                  Non sei ancora registrato?{" "}
                  <span
                    style={{
                      color: "#007bff",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => setIsRegistrazione(true)}
                  >
                    Registrati
                  </span>
                </p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: "32px",
              }}
            >
              {loading ? (
                <Button type="submit" color="primary" disabled>
                  <span className="spinner-border spinner-border-sm"></span>
                </Button>
              ) : (
                <Button
                  style={{
                    fontSize: "16px",
                  }}
                  type="submit"
                  color="primary"
                  disabled={!firstname || !lastname || !phoneNumber || !pin}
                >
                  {!!isRegistrazione ? "Registrati" : "Accedi"}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
