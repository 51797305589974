import { format } from "date-fns";
import { it, tr } from "date-fns/locale";
import icons from "bootstrap-italia/dist/svg/sprites.svg";
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../server";
import getHeaders from "../../../../utils/handleHeaderFunc";

const Conferma = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const bookingId = sessionStorage.getItem("bookingId");
  const [resumedData, setResumedData] = useState({});
  const [formattedDate, setFormattedDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const serviceData = JSON.parse(sessionStorage.getItem("serviceData"));

  useEffect(() => {
    const headers = getHeaders();
    if (!!bookingId) {
      try {
        const resumedData = async () => {
          const response = await axios.get(
            `${BASE_URL}/resume_booking/${bookingId}`,
            {
              headers: headers,
            }
          );

          setResumedData(response.data.booking);
        };

        resumedData();
      } catch (error) {
        console.log(error);
      }
    }
  }, [bookingId]);

  useEffect(() => {
    if (!!resumedData?.date) {
      setFormattedDate(
        format(new Date(resumedData?.date), "EEEE d MMMM yyyy", {
          locale: it,
        })
      );
      setStartTime(
        format(new Date(resumedData?.date), "HH:mm", {
          locale: it,
        })
      );
      const endTime = new Date(resumedData?.date).getTime() + 1800000;
      setEndTime(
        // 30 minutes in milliseconds
        format(new Date(endTime), "HH:mm", {
          locale: it,
        })
      );
    }
  }, [resumedData?.date]);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-12">
          <div className="cmp-hero">
            <section className=" bg-white align-items-start">
              <div className="it-hero-text-wrapper pt-0 ps-0 ">
                <div className="it-hero-text-content-wrapper w-100 mb-4 flex items-center">
                  <svg
                    className="icon icon-primary mr-2 icon-sm"
                    aria-hidden="true"
                  >
                    <use href={`${icons}#it-check-circle`}></use>
                  </svg>
                  <h1 className="text-black" data-element="page-name">
                    Appuntamento confermato
                  </h1>
                </div>

                <div className="hero-text ">
                  <p className="text-black">
                    L’appuntamento è fissato per{" "}
                    <strong>
                      {`${formattedDate} dalle ore ${startTime} alle ore ${endTime}.`}
                    </strong>
                    <br />
                    <br />
                    Abbiamo inviato il riepilogo all’email:
                    <br />
                    <strong>{userData.email}</strong>
                  </p>
                </div>
                {serviceData?.notification_after && (
                  <div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: serviceData.notification_after,
                      }}
                    ></p>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
      <hr className="d-none d-lg-block mb-4" />
    </div>
  );
};

export default Conferma;
